export default class SlickVehicleGallery {
    public static init() {
		const autoPlay = ($('.phyron-thumbnail').length > 0 ? false: true);
		if ($('.slick--vehicle-gallery-main').length > 0) {
			$('.slick--vehicle-gallery-main').slick({
				slidesToShow: 1,
				lazyLoad: "ondemand",
				slidesToScroll: 1,
				arrows: false,
				prevArrow: "<span class='slick-prev main'>&lt;</span>",
				nextArrow: "<span class='slick-next main'>&gt;</span>",
				asNavFor: '.slick--vehicle-gallery-thumbs',
				fade: true,
				autoplay: false,
			});
			$('.slick--vehicle-gallery-thumbs').slick({
				slidesToShow: 3,
				slidesToScroll: 1,
				vertical: true,
				verticalSwiping: true,
				arrows: true,
				prevArrow: "<span class='slick-prev thumbs'>&lt;</span>",
				nextArrow: "<span class='slick-next thumbs'>&gt;</span>",
				asNavFor: '.slick--vehicle-gallery-main',
				dots: false,
				centerMode: false,
				focusOnSelect: true,
				autoplay: false,
				responsive: [
					{
						breakpoint: 480,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 1,
							vertical: false,
							verticalSwiping: false,
						}
					}
				],
			});
			$('.slick--vehicle-gallery-main').each(function () {
				(<any>$(this)).magnificPopup({
					delegate: 'a',
					type: 'image',
					gallery: {
						enabled: true
					}
				});
			});
			$('.slick--vehicle-gallery-thumbs').each(function () {
				(<any>$(this)).magnificPopup({
					delegate: 'a',
					type: 'image',
					gallery: {
						enabled: true
					}
				});
			});
			$( '.slick--vehicle-gallery-main' ).on( 'lazyLoaded', function( evt, slick, $img ) {

				$img
					// Find the parent <picture> tag of img
					.closest('picture')
					// Find <source> tags with data-lazy-srcset attribute
					.find('source[data-lazy-srcset]')
					// Copy data-lazy-srcset to srcset
					.each(function (i: any, $source: any) {
						$source = $($source);
						$source.attr('srcset', $source.data('lazy-srcset'));
					}); 
			
			} );
			// $( '.slick--vehicle-gallery-thumbs' ).on( 'lazyLoaded', function( evt, slick, $img ) {

			// 	$img
			// 		// Find the parent <picture> tag of img
			// 		.closest('picture')
			// 		// Find <source> tags with data-lazy-srcset attribute
			// 		.find('source[data-lazy-srcset]')
			// 		// Copy data-lazy-srcset to srcset
			// 		.each(function (i: any, $source: any) {
			// 			$source = $($source);
			// 			$source.attr('srcset', $source.data('lazy-srcset'));
			// 		}); 
			// } );
		}
	}
}